// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-cart-tsx": () => import("./../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-in-stock-tsx": () => import("./../src/pages/inStock.tsx" /* webpackChunkName: "component---src-pages-in-stock-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-category-page-template-tsx": () => import("./../src/templates/CategoryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-category-page-template-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */)
}

