import { DeliverOptionsNode, ProductNode } from '../../interfaces/contentful';

export type AddToCartAction = {
  readonly type: 'cart/addToCart';
  payload: {
    quantity: number;
    newProduct: ProductNode;
  };
};

export type RemoveFromCartAction = {
  readonly type: 'cart/removeFromCart';
  payload: {
    quantity: number;
    newProduct: ProductNode;
  };
};

export type ClearCartAction = {
  readonly type: 'cart/clearCart';
};

export type AddDeliveryOptionToCartAction = {
  readonly type: 'cart/addDeliveryOptionToCart';
  payload: {
    quantity: number;
    newDeliveryOption: DeliverOptionsNode;
  };
};

export type RemoveDeliveryOptionFromCartAction = {
  readonly type: 'cart/removeDeliveryOptionFromCart';
  payload: {
    quantity: number;
    newDeliveryOption: DeliverOptionsNode;
  };
};

export type ClearDeliveryOptionsFromCartAction = {
  readonly type: 'cart/clearDeliveryOptionFromCart';
};

export type Actions =
  | AddToCartAction
  | RemoveFromCartAction
  | ClearCartAction
  | AddDeliveryOptionToCartAction
  | RemoveDeliveryOptionFromCartAction
  | ClearDeliveryOptionsFromCartAction;

export interface ShoppingCartProducts {
  [key: string]: ProductNode & { quantity: number };
}

export interface ShoppingCartDeliveryOptions {
  [key: string]: DeliverOptionsNode & { quantity: number };
}

export type State = {
  products: ShoppingCartProducts;
  deliveryOptions: ShoppingCartDeliveryOptions;
};

const getCachedState = (): State | null => {
  let state = null;
  if (typeof window !== `undefined` && window.localStorage.getItem('cart') !== null) {
    state = JSON.parse(window.localStorage.getItem('cart')!);
  }

  return state;
};

// TODO: verify if this can be done safer...and more beautiful
export const initialState: State = {
  products: getCachedState() ? getCachedState()!.products : {},
  deliveryOptions: getCachedState() ? getCachedState()!.deliveryOptions : {},
};

export function cartReducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'cart/clearDeliveryOptionFromCart':
      const updatedClearedState = {
        ...state,
        deliveryOptions: {},
      };
      window.localStorage.setItem('cart', JSON.stringify(updatedClearedState));
      return updatedClearedState;
    case 'cart/addDeliveryOptionToCart':
      const updatedDeliveryOptions = {
        ...state,
        deliveryOptions: {
          ...state.deliveryOptions,
          [action.payload.newDeliveryOption.contentful_id]: {
            ...state.deliveryOptions[action.payload.newDeliveryOption.contentful_id],
            ...action.payload.newDeliveryOption,
            quantity: state.deliveryOptions[action.payload.newDeliveryOption.contentful_id]
              ? state.deliveryOptions[action.payload.newDeliveryOption.contentful_id].quantity + action.payload.quantity
              : action.payload.quantity,
          },
        },
      };
      window.localStorage.setItem('cart', JSON.stringify(updatedDeliveryOptions));
      return updatedDeliveryOptions;

    case 'cart/removeDeliveryOptionFromCart':
      if (state.deliveryOptions[action.payload.newDeliveryOption.contentful_id]) {
        // In this, case, remove the whole deliveryOption key from the object
        if (state.deliveryOptions[action.payload.newDeliveryOption.contentful_id].quantity <= action.payload.quantity) {
          const {
            [action.payload.newDeliveryOption.contentful_id]: _,
            ...otherDeliveryOptions
          } = state.deliveryOptions;

          const updatedState = {
            ...state,
            deliveryOptions: {
              ...otherDeliveryOptions,
            },
          };

          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
          // In this case, just decrease the quantity of given deliveryOption key
        } else {
          const updatedState = {
            ...state,
            deliveryOptions: {
              ...state.deliveryOptions,
              [action.payload.newDeliveryOption.contentful_id]: {
                ...state.deliveryOptions[action.payload.newDeliveryOption.contentful_id],
                ...action.payload.newDeliveryOption,
                quantity:
                  state.deliveryOptions[action.payload.newDeliveryOption.contentful_id].quantity -
                  action.payload.quantity,
              },
            },
          };
          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
        }
      } else {
        const updatedState = {
          ...state,
          deliveryOptions: {
            ...state.deliveryOptions,
          },
        };
        window.localStorage.setItem('cart', JSON.stringify(updatedState));
        return updatedState;
      }

    case 'cart/clearCart':
      const updatedClearCartState = {
        ...state,
        products: {},
        deliveryOptions: {},
      };
      window.localStorage.setItem('cart', JSON.stringify(updatedClearCartState));
      return updatedClearCartState;
    case 'cart/addToCart':
      const updatedState = {
        ...state,
        products: {
          ...state.products,
          [action.payload.newProduct.contentful_id]: {
            ...state.products[action.payload.newProduct.contentful_id],
            ...action.payload.newProduct,
            quantity: state.products[action.payload.newProduct.contentful_id]
              ? state.products[action.payload.newProduct.contentful_id].quantity + action.payload.quantity
              : action.payload.quantity,
          },
        },
      };

      window.localStorage.setItem('cart', JSON.stringify(updatedState));
      return updatedState;
    case 'cart/removeFromCart':
      if (state.products[action.payload.newProduct.contentful_id]) {
        if (state.products[action.payload.newProduct.contentful_id].quantity <= action.payload.quantity) {
          const { [action.payload.newProduct.contentful_id]: _, ...others } = state.products;
          // console.log('toRemove', action.payload.newProduct.contentful_id, state.products);

          const updatedState = {
            ...state,
            products: {
              ...others,
            },
          };

          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
        } else {
          const updatedState = {
            ...state,
            products: {
              ...state.products,
              [action.payload.newProduct.contentful_id]: {
                ...state.products[action.payload.newProduct.contentful_id],
                ...action.payload.newProduct,
                quantity: state.products[action.payload.newProduct.contentful_id].quantity - action.payload.quantity,
              },
            },
          };
          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
        }
      } else {
        const updatedState = {
          ...state,
          products: {
            ...state.products,
          },
        };
        window.localStorage.setItem('cart', JSON.stringify(updatedState));
        return updatedState;
      }
    default:
      throw new Error();
  }
}
