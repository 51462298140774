import React from 'react';
import { Layout } from './src/components/layout';
import { IntlProvider } from 'react-intl';

import messagesZH from './src/translations/zh.json';
import { StateProvider } from './src/store/StateProvider';

export const wrapRootElement = ({ element, pathname }) => {
  return (
    <IntlProvider locale="zh-CN" messages={messagesZH}>
      <StateProvider>{element}</StateProvider>
      {/*<Layout location={pathname}>{element}</Layout>*/}
    </IntlProvider>
  );
};

export const wrapPageElement = ({ element, pathname }) => {
  return <Layout location={pathname}>{element}</Layout>;
};

/**
 * @param {RouteUpdateArgs} args
 *
 * @see more https://developers.google.com/analytics/devguides/collection/gtagjs/pages#pageview_parameters
 */
export const onRouteUpdate = (args) => {
  /* eslint-disable @typescript-eslint/camelcase */
  const sendPageView = () => {
    window.gtag &&
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: args.location.href,
        page_path: args.location.pathname,
      });
  };
  /* eslint-enable @typescript-eslint/camelcase */

  // @see for inspiration https://github.com/gatsbyjs/gatsby/pull/12033/files
  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    setTimeout(sendPageView, 32);
  }
};
