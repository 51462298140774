import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, List, ListItem } from '@material-ui/core';
import { theme } from '../assets/jss/theme';
import { Footer } from './Footer/Footer';
import { Link as GatsbyLink } from 'gatsby';
import { footerStyle } from './Footer/FooterStyle';
import { FormattedMessage } from 'react-intl';

interface LayoutProps {
  location: string;
}

const useStyles = makeStyles(footerStyle);

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                {/* TODO: BringBackPosts*/}
                {/*<ListItem className={classes.inlineBlock}>*/}
                {/*  <GatsbyLink to={`/posts`} className={classes.block}>*/}
                {/*    <FormattedMessage id="general.blogPosts" defaultMessage="Blog Posts" />*/}
                {/*  </GatsbyLink>*/}
                {/*</ListItem>*/}
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/inStock`} className={classes.block}>
                    <FormattedMessage id="inStock.title" defaultMessage="In Stock" />
                  </GatsbyLink>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/cookies`} className={classes.block}>
                    <FormattedMessage id="cookies.title" defaultMessage="Cookies" />
                  </GatsbyLink>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {new Date().getFullYear()},{' '}
              <a href="https://tekmi.nl" target="_blank" rel="noopener noreferrer">
                TekMi
              </a>
            </div>
          </div>
        }
      />
    </ThemeProvider>
  );
};
