module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"艾宝妈中文童书 - 给孩子最好的精神滋养","short_name":"艾宝妈中文童书","start_url":"/","background_color":"#f7f0eb","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/img/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7f8e5bba8e6fc9e3331542caa7300996"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
